<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @close="onClose"
    title="商品分类详情"
    :visible.sync="visible"
    width="600px"
    >

      <el-descriptions class="margin-top" :column="1"
      border>


            <el-descriptions-item label="ID">
            {{ row.id }}
            </el-descriptions-item>

            <el-descriptions-item label="分类名称">
            {{ row.name }}
            </el-descriptions-item>

            <el-descriptions-item label="分类编码">
            {{ row.code }}
            </el-descriptions-item>

            <el-descriptions-item label="分类状态" v-if="enumMaps['WmsSkuCategoryStatus']">
                 {{enumMaps['WmsSkuCategoryStatus'][row.status]}}
            </el-descriptions-item>


            <el-descriptions-item label="创建时间">
            {{ row.createTime }}
            </el-descriptions-item>




      </el-descriptions>


      <div slot="footer">
        <el-button @click="close">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums } from "@/api/common"
import { detailWmsSkuCategory } from "@/api/wms/wmsSkuCategory"

export default {
  inheritAttrs: false,
  components: {},
  props: [],
  data() {
    return {
        visible: false,
        row: {
            id: '',
            name: '',
            code: '',
            status: '',
            createTime: '',
        },
        enums: {},
        enumMaps: {},
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsSkuCategoryStatus')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    detail() {
        const param = {
            id: this.row.id
        }
        detailWmsSkuCategory(param).then(res=>{
          if(res.code === 200) {
            this.row = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    onOpen(row) {
        console.log("onOpen", row)
        this.visible = true
        this.row = row
        this.detail()
    },
    onClose() {
    },
    close() {
      this.visible = false
    },
  }
}

</script>
<style>
</style>
